import axios from 'axios'


const POLICY_BASE_URL = `/api/policy-service`


export const changeOvernightAddress = async (
  policyId: string,
  data = {},
  date: string,
  params: string,
  token: string,
) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const mta: { status: number; data: any } = await axios({
    url: `${POLICY_BASE_URL}/policies/TYA%252F${policyId}/address?effectiveDateTime=${date}${params}`,
    method: 'PUT',
    data: JSON.stringify(data),
    headers: headers,
  })
    .then(async response => {
      if (response.status === 401) {
        return {
          status: 401,
          data: 'Token refresh failed',
        }
      }
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error,
        data: error.response,
      }
    })

  return mta
}

export const changeCar = async (policyId: string, data = {}, date: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const mta: { status: number; data: any } = await axios({
    url: `${POLICY_BASE_URL}/policies/TYA%252F${policyId}/vehicle?effectiveDateTime=${date}`,
    method: 'PUT',
    data: JSON.stringify(data),
    headers: headers,
  })
    .then(async response => {
      if (response.status === 401) {
        return {
          status: 401,
          data: 'Token refresh failed',
        }
      }
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error,
        data: error.response,
      }
    })

  return mta
}

export const addNewDriver = async (policyId: string, data = {}, date: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const mta: { status: number; data: any } = await axios({
    url: `${POLICY_BASE_URL}/policies/TYA%252F${policyId}/drivers?effectiveDateTime=${date}`,
    method: 'POST',
    data: JSON.stringify(data),
    headers: headers,
  })
    .then(async response => {
      if (response.status === 401) {
        return {
          status: 401,
          data: 'Token refresh failed',
        }
      }
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error,
        data: error.response,
      }
    })

  return mta
}

export const validateCard = async (jobId: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const mta: { status: number; data: any } = await axios({
    url: `${POLICY_BASE_URL}/policies/${jobId}/validatecard`,
    method: 'GET',
    headers: headers,
  })
    .then(async response => {
      if (response.status === 401) {
        return {
          status: 401,
          data: 'Token refresh failed',
        }
      }
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error,
        data: error.response,
      }
    })

  return mta
}

export const getRemainingInstallments = async (jobId: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const mta: { status: number; data: any } = await axios({
    url: `${POLICY_BASE_URL}/policies/${jobId}/getremaininginstalments`,
    method: 'GET',
    headers: headers,
  })
    .then(async response => {
      if (response.status === 401) {
        return {
          status: 401,
          data: 'Token refresh failed',
        }
      }
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error,
        data: error.response,
      }
    })

  return mta
}

export const bindPayment = async (jobId: string, token: string, reuseExistingCard: boolean) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const mta: { status: number; data: any } = await axios({
    url: `${POLICY_BASE_URL}/policies/${jobId}/bindpayment`,
    method: 'POST',
    data: JSON.stringify({ reuseExistingCard_itb: reuseExistingCard }),
    headers: headers,
  })
    .then(async response => {
      if (response.status === 401) {
        return {
          status: 401,
          data: 'Token refresh failed',
        }
      }
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error,
        data: error.response,
      }
    })

  return mta
}

export const addBillingDetailsAndGetSagepayUrl = async (
  billingAddress: any,
  paymentDetails: any,
  jobId: string,
  token: string,
  storeCardForFuture: any,
  autoRenewValue: any,
) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const mta: { status: number; data: any } = await axios({
    url: `${POLICY_BASE_URL}/policies/${jobId}/addbillingdetailsandgetsagepayurl`,
    method: 'POST',
    data: JSON.stringify({
      billingAddress: billingAddress,
      paymentDetails: paymentDetails,
      applicationType: 'SFE_AMP_TYA',
      storeCardForFuture: storeCardForFuture,
      autoRenew: autoRenewValue,
    }),
    headers: headers,
  })
    .then(async response => {
      if (response.status === 401) {
        return {
          status: 401,
          data: 'Token refresh failed',
        }
      }
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error,
        data: error.response,
      }
    })

  return mta
}

export const multiMTAChange = async (policyId: string, requestObject: any, date: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const mta: { status: number; data: any } = await axios({
    url: `${POLICY_BASE_URL}/policies/TYA%252F${policyId}/policyChange?effectiveDateTime=${date}`,
    method: 'PUT',
    data: JSON.stringify(requestObject),
    headers: headers,
  })
    .then(async response => {
      if (response.status === 401) {
        return {
          status: 401,
          data: 'Token refresh failed',
        }
      }
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error,
        data: error.response,
      }
    })

  return mta
}

export const createMultiChange = async (policyId: string, jobId: string, date: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const mta: { status: number; data: any } = await axios({
    url: `${POLICY_BASE_URL}/policies/TYA%252F${policyId}/quote/${jobId}?effectiveDateTime=${date}`,
    method: 'POST',
    data: JSON.stringify({
      jobId: jobId,
      status: 'Draft',
    }),
    headers: headers,
  })
    .then(async response => {
      if (response.status === 401) {
        return {
          status: 401,
          data: 'Token refresh failed',
        }
      }
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error,
        data: error.response,
      }
    })

  return mta
}

export const removeDriver = async (policyId: string, jobId: string, date: string, token: string) => {
  const headers = {
    'x-authorization': token,
    'Content-Type': 'application/json',
  }

  const quote: { status: number; data: any } = await axios({
    url: `${POLICY_BASE_URL}/policies/TYA%252F${policyId}/drivers/pc%253A${jobId}?effectiveDateTime=${date}`,
    method: 'DELETE',
    headers: headers,
  })
    .then(async response => {
      if (response.status === 401) {
        return {
          status: 401,
          data: 'Token refresh failed',
        }
      }
      return {
        status: response.status,
        data: response.data,
      }
    })
    .catch(error => {
      return {
        status: error,
        data: error.response,
      }
    })

  return quote
}
