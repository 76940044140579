import { connect } from 'react-redux'
import { getAddressByIdAction, getAddressByPostalCodeAction } from 'redux/account/actions'
import { cancelMTAChangesAction, executeMTAChangesAction } from 'redux/mta/actions'

import { IReduxState } from 'types/stateTypes'
import YourPaymentPage from './MTANewCardPage'

const mapStateToProps = (state: IReduxState) => ({
  mtaResponse: state.mta.mtaResponse,
  current: state.policy.current,
  showMTAExecuteModal: state.mta.showMTAExecuteModal,
  lookupAddresses: state.account.lookupAddresses,
  selectedAddress: state.account.selectedAddress,
  autoRenew: state.policy.autoRenew,
  previousSavedCardStatus: state.policy.previousSavedCardStatus,
})

const mapDispatchToProps = {
  cancelMTAChangesAction: cancelMTAChangesAction.start,
  executeMTAChangesAction: executeMTAChangesAction.start,
  getAddressByPostalCodeAction: getAddressByPostalCodeAction.start,
  getAddressByIdAction: getAddressByIdAction.start,
}

export default connect(mapStateToProps, mapDispatchToProps)(YourPaymentPage)
