import React from 'react'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import { Form, Formik, FormikProps } from 'formik'
import { Box, Button, Grid, Grow, Typography, } from '@material-ui/core'

import YourAddress from 'components/ui/YourAddress'
import BillingDetailsSection from 'components/payment/BillingDetailsSection'
import CardRegisteredToYou from 'components/payment/CardRegisteredToYou'
import AddressLookup from 'components/ui/AddressLookup'

import Layout from 'components/ui/Layout'
import Title from 'components/ui/Title'

import { CardRegisteredToYouContent } from 'components/payment/CardRegisteredToYou/CardRegisteredToYou'
import { YourChosenAddressContent } from 'components/ui/YourAddress/YourAddress'
import { BillingDetailsSectionContent } from 'components/payment/BillingDetailsSection/BillingDetailsSection'

import { getContentById } from 'utils'
import { formContentItem } from 'utils/content/contentItemMapper'

import validationSchema, { initialValues } from './validationSchema'

import content from './mtaNewCardContent.json'
import { guidewireQuoteTools } from 'guidewire/guidewireQuoteTools'
import { AddressById } from 'types/responses'
import { isEmpty, getDateXdaysBefore } from 'guidewire/formatTools'
import Section from 'components/ui/Section'

export interface Props {
  current: any
  mtaResponse: any
  autoRenew: boolean
  previousSavedCardStatus: boolean
  lookupAddresses: any[]
  selectedAddress: AddressById
  getAddressByPostalCodeAction: (postalCode: string) => void
  getAddressByIdAction: (postalCode: string) => void
  executeMTAChangesAction: (x: any) => void
  cancelMTAChangesAction: VoidFunction
}

const MTANewCardPage = ({
  current,
  mtaResponse,
  autoRenew,
  previousSavedCardStatus,
  lookupAddresses,
  selectedAddress,
  getAddressByPostalCodeAction,
  getAddressByIdAction,
  executeMTAChangesAction,
  cancelMTAChangesAction,
}: Props) => {
  const [showBillingDetails, setShowBillingDetails] = React.useState(false)
  const [showAddressLookup, setShowAddressLookup] = React.useState(false)
  const [addressLineOneEdit, setAddressLineOneEdit] = React.useState(false)
  const [is30DaysBeforeExpiry, setIs30DaysBeforeExpiry] = React.useState(false)

  const gw = guidewireQuoteTools(current)

  const formRef = React.useRef<FormikProps<any>>(null)

  const formValues = {
    isMonthly: mtaResponse.paymentPlanId === 'bc:6',
    ...initialValues,
  }

  // Shows or hides extra fields a third party payer needs to fill out
  const handleCardRegistration = (value: string) => {
    if (value === 'no') setShowBillingDetails(true)
    else setShowBillingDetails(false)
  }

  const handleSubmit = (values: any) => {
    const registeredToYou = values['isCardRegisteredToYou'] === 'yes' ? true : false
    const requestData = {
      ...mtaResponse,
      billingAddress: isEmpty(selectedAddress) === false ? selectedAddress : gw.primaryAccountHolder().address(),
      paymentDetails: {
        creditCardData: {
          isCardRegisteredToYou: registeredToYou,
          ...(registeredToYou === false && {
            cardHolderFirstname: values['cardHolderFirstname'],
            cardHolderSurname: values['cardHolderSurname'],
            cardHolderEmailAddress: values['cardHolderEmailAddress'],
            cardHolderPhoneNumber: values['cardHolderPhoneNumber'],
          }),
        },
      },
    }

    executeMTAChangesAction({
      current: current,
      mtaResponse: requestData,
      existingCard: false,
      autoRenew : autoRenew,
      previousSavedCardStatus: previousSavedCardStatus,
    })
  }

  // Date dependant content changes
  // If policy start date is before 04/04/2022 it needs to show different legal text to after this date
  // only applies to DD payments
  const getDirectDebitText = () => {
    const before = getContentById(content, 'before_importantInformationDirectDebit')
    const after = getContentById(content, 'after_importantInformationDirectDebit')

    const isBefore = moment(
      gw
        .policy()
        .start()
        .details(),
    ).isBefore('04/04/2022')

    if (isBefore === true) {
      return (
        <Box pt={5} pb={5}>
          <Title title={before.title} />
          <ReactMarkdown>{before.body}</ReactMarkdown>
        </Box>
      )
    }

    return (
      <Box pt={5} pb={5}>
        <Title title={after.title} />
        <ReactMarkdown>{after.body}</ReactMarkdown>
      </Box>
    )
  }

  React.useEffect(() => {
      const todaysDate = moment(new Date())
        .utc(false)
        .toISOString()
      const renewDate = getDateXdaysBefore(30, current?.expiration)
      setIs30DaysBeforeExpiry(moment(todaysDate).isBefore(renewDate))
  }, [])

  React.useEffect(() => {
    // After user has successfully changed their billing address, resets the field values and shows billing address
    if (isEmpty(selectedAddress) === false) {
      formRef.current?.setFieldValue('postalCodeLookup', '')
      formRef.current?.setFieldValue('addressSelect', 'none')
      // Makes sure the editable addressLine1 has the initial found address value
      formRef?.current?.setFieldValue('addressLine1', selectedAddress.addressLine1)
      formRef.current?.setFieldValue('showAddressLookup', false)
      setShowAddressLookup(false)
    }
  }, [selectedAddress])

  return (
    <>
      <Layout>
        <Box pt={5}>
          <Grid item xs={12}>
            <Section>
              <Formik
                innerRef={formRef}
                initialValues={formValues}
                onSubmit={async values => handleSubmit(values)}
                validationSchema={validationSchema()}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
              >
                {props => (
                  <Form onSubmit={props.handleSubmit}>
                    <Box pt={5} pb={5}>
                      <CardRegisteredToYou
                        content={
                          formContentItem(getContentById(content, 'cardDetailsSection')) as CardRegisteredToYouContent
                        }
                        onSelect={(value: string) => handleCardRegistration(value)}
                      />

                      <Typography  component="div">
                          {is30DaysBeforeExpiry && !formValues.isMonthly ? 
                            <>
                            <h2>Automatic Renewal and Payment Details</h2> 
                            
                            <p>This policy automatically renews every year. This means that at renewal, if you want to continue your insurance with us, you don&#39;t need to do anything. We&#39;ll use the payment details you share with us today to do this and for any other policy changes you make throughout the policy term.</p>
                            <p>You can update your renewal preferences in Your Portal, via live chat or by calling us. You&#39;ll need to do this 30 days before your policy ends if you don&#39;t want to automatically renew.</p>
                            <p>After setting up your policy, if you don&#39;t want your card details saved, please contact our team via live chat or call us.</p>                           
                            </>
                            : null}
                        </Typography>

                      <Grow unmountOnExit timeout={300} in={showBillingDetails}>
                        <div>
                          <BillingDetailsSection
                            content={
                              formContentItem(
                                getContentById(content, 'billingDetailsSection'),
                              ) as BillingDetailsSectionContent
                            }
                          />
                        </div>
                      </Grow>

                      <Grow unmountOnExit timeout={300} in={showAddressLookup === false}>
                        <YourAddress
                          form={props}
                          content={
                            (formContentItem(
                              getContentById(content, 'yourAddress'),
                            ) as unknown) as YourChosenAddressContent
                          }
                          address={
                            isEmpty(selectedAddress) === false
                              ? selectedAddress
                              : (gw.primaryAccountHolder().address() as any)
                          }
                          onChangeAddress={() => {
                            formRef.current?.setFieldValue('showAddressLookup', true)
                            setShowAddressLookup(true)
                          }}
                          addressLineOneEdit={addressLineOneEdit}
                          editAddressLineOne={() => console.log('editBillingAddressLineOneAction')}
                        />
                      </Grow>

                      <Grow unmountOnExit timeout={300} in={showAddressLookup}>
                        <AddressLookup
                          onFindMyAddress={(value: string) => getAddressByPostalCodeAction(value)}
                          onAddressSelect={(value: string) => {
                            getAddressByIdAction(value)
                            setAddressLineOneEdit(true)
                          }}
                          lookupAddresses={lookupAddresses}
                          errorMessage={'Please ensure you are providing a valid postcode'}
                        />
                      </Grow>
                    </Box>

                    <Box pt={2.5} pr={2.5} pl={2.5} pb={4}>
                      <Grid container justify="center" spacing={2}>
                        <Grid item>
                          <Button variant="outlined" color="secondary" onClick={() => cancelMTAChangesAction()}>
                            {'Cancel'}
                          </Button>
                        </Grid>

                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              const submit = async () => await props.submitForm()
                              submit()
                            }}
                          >
                            Complete purchase
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Section>
          </Grid>
        </Box>
      </Layout>
    </>
  )
}

export default MTANewCardPage
